import { rootDoc } from "../../../../helpers/dom-selectors.js";
import PasswordInput from "../../../global-components/password-input.js";
import ForgotPassBtn from "../components/forgot-pass-btn.js";
import ForgotPassForm from "../components/forgot-pass-form.js";
import LoginForm from "../components/login-form.js";
import APP_VIEWS from "./event-router.js";
export default function App({ inputPass, showPassBtn }) {
    _initAppShell();
    return Object.freeze({
        resize,
        executeEvent
    });
    function _initAppShell() {
        PasswordInput({ inputPass, showPassBtn });
        ForgotPassBtn.init();
        ForgotPassForm.init();
        LoginForm.init();
    }
    function resize() {
        const vh = window.innerHeight / 100;
        rootDoc.style.setProperty("--vh", vh + "px");
    }
    async function executeEvent(action) {
        const view = APP_VIEWS.get(action);
        await view.execute();
    }
}
;
