import makeFormRequest from "../../../../helpers/form-request.js";
import ServerResponse from "../../../../helpers/render-server-response.js";
import Request from "../../../../helpers/request.js";
import ForgotPassForm from "../components/forgot-pass-form.js";
export default {
    execute: async () => {
        const { form, submitBtn } = ForgotPassForm.get();
        const payload = { email: form.email.value.trim().toLowerCase() };
        const formRequest = makeFormRequest({ form, submitBtn });
        const response = await Request.post('/forgot-pass', payload);
        formRequest.afterResponse();
        if (response.success) {
            ServerResponse.show({
                resp: response,
                respTitle: 'SUCCESS',
                respText: `An email with a password reset link has been sent to the following address:<br><br><strong><u>${response.email}</u></strong>`,
                onRedirect: '/'
            });
            return;
        }
        ServerResponse.show({
            resp: response,
            respTitle: 'ERROR',
            respText: response.error
        });
    }
};
