import { $, rootDoc } from "../../helpers/dom-selectors.js";
export default class Overlay {
    _overlay;
    _redirectUrl;
    _overlayBox;
    constructor(overlay, redirectUrl) {
        this._overlay = overlay;
        this._redirectUrl = redirectUrl;
        this._addOnCloseListener();
        this._overlayBox = $(this._overlay, '.overlay-box')[0];
    }
    set(redirectUrl) {
        this._redirectUrl = redirectUrl;
    }
    render(content) {
        const wrapper = $(this._overlay, '.overlay-content')[0];
        wrapper.replaceChildren();
        wrapper.append(content);
        this.show();
    }
    show() {
        rootDoc.classList.add('no-scroll');
        this._overlay.classList.add('overlay-change');
        this._overlayBox.classList.add('overlay-box-change');
    }
    _addOnCloseListener() {
        this._overlay.addEventListener('click', (e) => {
            this._onClose(e);
        });
    }
    _onClose(e) {
        const target = e.target;
        if (target === this._overlay)
            this.hide();
    }
    hide() {
        rootDoc.classList.remove('no-scroll');
        this._overlayBox.classList.remove('overlay-box-change');
        setTimeout(() => {
            this._overlay.classList.remove('overlay-change');
            if (this._redirectUrl)
                window.location.href = this._redirectUrl;
        }, 300);
    }
}
