export default function PassWordInput({ inputPass, showPassBtn }) {
    _init();
    function _init() {
        inputPass.addEventListener('focus', (e) => {
            _onInput(e);
        });
        inputPass.addEventListener('keyup', (e) => {
            _onInput(e);
        });
        showPassBtn.addEventListener('click', (e) => {
            _onShowBtnClick(e);
        });
    }
    function _onInput(e) {
        const target = e.currentTarget;
        if (target.value === '')
            showPassBtn.classList.add('no-display');
        else
            showPassBtn.classList.remove('no-display');
    }
    function _onShowBtnClick(e) {
        const target = e.currentTarget;
        if (target.innerText === 'SHOW') {
            target.innerText = 'HIDE';
            inputPass.type = 'text';
        }
        else {
            target.innerText = 'SHOW';
            inputPass.type = 'password';
        }
    }
}
