export default function makeEvents(actions) {
    let events = [];
    actions.forEach(action => {
        events.push({
            event: new CustomEvent('app-event', {
                detail: {
                    action: action
                }
            }),
            action
        });
    });
    return Object.freeze({
        emit
    });
    function emit(action) {
        const ev = events.find(event => event.action === action);
        window.dispatchEvent(ev.event);
    }
}
