import { _ } from "../../../../helpers/dom-selectors.js";
import APP_EVENTS from "../init/events.js";
import makeForm from "../../../global-components/form.js";
const ForgotPassForm = makeForm({
    form: _('forgotForm'),
    submitBtn: _('forgotBtn'),
    preventDefault: true,
    events: APP_EVENTS,
    action: 'forgot-pass-form-submitted',
});
export default ForgotPassForm;
