export function createEl(tag, classes, attr) {
    const el = document.createElement(tag);
    el.setAttribute('class', classes);
    if (attr) {
        for (let key in attr) {
            el.setAttribute(key, attr[key]);
        }
    }
    return el;
}
export function createElWithText(tag, classes, text, attr) {
    const el = createEl(tag, classes, attr);
    el.innerText = text;
    return el;
}
export function createElWithHTML(tag, classes, html, attr) {
    const el = createEl(tag, classes, attr);
    el.innerHTML = html;
    return el;
}
export function createImageEl(classes, attr) {
    return createEl('img', classes, attr);
}
export function createAudioEl(classes, attr) {
    return createEl('audio', classes, attr);
}
export function createVideoEl(classes, attr) {
    return createEl('video', classes, attr);
}
export function createBtn(classes, attr) {
    return createElWithText('button', classes, attr.text, attr);
}
export function createBtnWithSpinner(classes, attr) {
    const btn = createEl('button', classes, attr);
    const spinner = createEl('div', 'spinner no-display');
    const span = createElWithText('span', 'btn-span', attr.text);
    btn.append(spinner, span);
    return btn;
}
export function createFragment(fragments) {
    const fragment = document.createDocumentFragment();
    fragments.forEach(fr => fragment.append(fr));
    return fragment;
}
//DOM APPENDS
export function appendEl(tag, classes, target, attr) {
    const el = createEl(tag, classes, attr);
    target.append(el);
}
export function appendElWithText(tag, classes, target, text, attr) {
    const el = createElWithText(tag, classes, text, attr);
    target.append(el);
}
export function appendElWithHTML(tag, classes, target, html, attr) {
    const el = createElWithHTML(tag, classes, html, attr);
    target.append(el);
}
