import forgotPassFormSubmitted from "../events/forgot-pass-form-submitted.js";
import loginFormSubmitted from "../events/login-form-submitted.js";
function makeEventRouter() {
    const router = {
        'forgot-pass-form-submitted': forgotPassFormSubmitted,
        'login-form-submitted': loginFormSubmitted
    };
    return Object.freeze({
        get
    });
    function get(action) {
        return router[action];
    }
}
;
const APP_VIEWS = makeEventRouter();
export default APP_VIEWS;
