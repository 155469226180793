import { _ } from "../../../../helpers/dom-selectors.js";
import makeForm from "../../../global-components/form.js";
import APP_EVENTS from "../init/events.js";
const LoginForm = makeForm({
    form: _('loginForm'),
    submitBtn: _('loginBtn'),
    preventDefault: false,
    events: APP_EVENTS,
    action: 'login-form-submitted',
});
export default LoginForm;
