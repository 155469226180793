import { $ } from "../../../helpers/dom-selectors.js";
import App from "./init/app.js";
(async function () {
    const overlays = $(document, '.overlay');
    overlays.forEach(overlay => overlay.classList.remove('no-display'));
    const app = App({
        inputPass: $(document, '.input-pass')[0],
        showPassBtn: $(document, '.show-btn')[0],
    });
    window.addEventListener('resize', app.resize);
    app.resize();
    window.addEventListener('app-event', (e) => {
        app.executeEvent(e.detail.action);
    });
    document.documentElement.addEventListener('touchstart', function (e) { }); //hover behaviour on touch devices
})();
