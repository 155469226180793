export default function makeForm({ form, submitBtn, preventDefault, events, action }) {
    let initialized = false;
    return Object.freeze({
        init,
        get,
        clear,
        append
    });
    function init() {
        if (!form || !submitBtn)
            return;
        if (initialized)
            return;
        form.addEventListener('submit', (e) => {
            if (preventDefault)
                e.preventDefault();
            events.emit(action);
        });
        initialized = true;
    }
    function get() { return { form, submitBtn }; }
    function clear() {
        form.replaceChildren();
    }
    function append(fragment) {
        form.append(fragment);
    }
}
