import { $ } from "../../helpers/dom-selectors.js";
export default function makeBtnLoader({ btn }) {
    const submitBtn = btn;
    const spinner = $(submitBtn, '.spinner')[0];
    const buttonSpan = $(submitBtn, '.btn-span')[0];
    return Object.freeze({
        reset,
        onLoading
    });
    function reset() {
        _btnLoader(false);
    }
    function onLoading() {
        _btnLoader(true);
    }
    function _btnLoader(isLoading) {
        if (isLoading) {
            submitBtn.disabled = true;
            spinner.classList.remove("no-display");
            buttonSpan.classList.add("no-display");
        }
        else {
            submitBtn.disabled = false;
            spinner.classList.add("no-display");
            buttonSpan.classList.remove("no-display");
        }
    }
}
