import Overlay from "../app/global-components/overlay.js";
import { createBtn, createElWithHTML, createElWithText, createFragment } from "./dom-creators.js";
import { _ } from "./dom-selectors.js";
import { createErrorSVG, createSuccessSVG } from "./svg.js";
function makeResponse() {
    const overlay = new Overlay(_('resultOverlay'));
    return Object.freeze({
        show,
    });
    function show({ ...args }) {
        const classes = (args.resp.success) ? 'text-center' : 'error text-center';
        const svg = (args.resp.success) ? createSuccessSVG() : createErrorSVG();
        const textHTML = (args.resp.success || args.resp.error) ? args.respText : 'A server-side error occured';
        const btnClasses = (args.resp.success) ? 'submit-btn no-margin btns' : 'submit-btn error-btn no-margin btns';
        const okBtn = createBtn(btnClasses, { type: 'button', title: 'OK', text: 'OK' });
        const fragments = [createElWithText('h1', classes, args.respTitle), svg, createElWithHTML('p', classes, textHTML), okBtn];
        const fragment = createFragment(fragments);
        overlay.render(fragment);
        if (args.onRedirect)
            overlay.set(args.onRedirect);
        okBtn.addEventListener('click', () => {
            overlay.hide();
        });
    }
}
const ServerResponse = makeResponse();
export default ServerResponse;
