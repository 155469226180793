function makeRequest() {
    return Object.freeze({
        post
    });
    async function post(url, payload) {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };
        try {
            const response = await fetch(url, options);
            return await response.json();
        }
        catch (err) {
            console.error(err);
            return { success: false, error: 'An unexpected error occured<br><br>Please check your internet connection' };
        }
    }
}
const Request = makeRequest();
export default Request;
