import { _ } from "../../../../helpers/dom-selectors.js";
import Overlay from "../../../global-components/overlay.js";
function makeForgotPassBtn({ forgotPassEl, forgotPassOverlay }) {
    let initialized = false;
    return Object.freeze({
        init
    });
    function init() {
        if (initialized)
            return;
        const ForgotPassOverlay = new Overlay(forgotPassOverlay);
        forgotPassEl.addEventListener('click', () => {
            ForgotPassOverlay.show();
        });
        initialized = true;
    }
}
const ForgotPassBtn = makeForgotPassBtn({
    forgotPassEl: _('forgotPass'),
    forgotPassOverlay: _('forgotOverlay'),
});
export default ForgotPassBtn;
